* {
    box-sizing: border-box;
}

body {
    font-family: sans-serif;
    margin: 0;
}

select {
    width: 100%;
    font: inherit;
    border: 0;
    border-radius: 100px;
    padding: 10px 20px;
    font-family: sans-serif;
    font-weight: normal;
}

a {
    text-decoration: none;
    color: inherit;
    &:hover {
        text-decoration: underline;
    }
}

.start {
    max-width: 800px;
    margin: auto;
    text-align: center;
    margin-top: 100px;
    button {
        width: 100%;
        font-size: 40px;
        font-family: inherit;
        display: block;
        margin: auto;
        padding: 20px;
        border: 0;
        border-radius: 100px;
        background: #fff;
        border: 1px solid #ddd;
        cursor: pointer;
        margin-top: 40px;
    }
    textarea {
        padding: 20px;
        color: hsl(231deg 49% 60%);
        font-family: Menlo;
    }
}

.token-viewer-small {
    margin-bottom: 40px;
}

.fancy-frame {
    background: linear-gradient(0deg, #444, #a5a5a5);
    padding: 10px;
    border: 3px solid #444;
    position: relative;
    box-shadow: 0 22px 11px -5px #00000070;
    margin-bottom: 18px;

    img {
        border: 20px solid white;
        display: block;
        background: #fff;
        position: relative;
        min-height: 300px;
        margin: auto;
        max-width: 100%;
        &:after {
            content: '-\A\APreview Unavailable';
            white-space: pre;

            font-size: 16px;

            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: whitesmoke;
            text-align: center;
            color: #bbb;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &::after {
        content: ' ';
        height: 0px;
        display: block;
        position: absolute;
        right: -3px;
        left: -3px;
        top: 100%;
        border: 10px solid transparent;
        border-top-color: black;
        margin-top: 3px;
    }
}

.fancy-label {
    background: #fff;
    padding: 20px;
    box-shadow: 0 2px 4px #0000001f;
    margin-bottom: 10px;
    border-radius: 5px;
    margin: 0 auto;
    width: 280px;
    overflow: hidden;
    .name {
        font-family: Georgia;
        font-size: 30px;
        font-style: italic;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .contract {
        margin: 10px 0;
    }
    .description {
        font-size: 12px;
        word-break: break-word;
        p:last-child {
            margin-bottom: 0;
        }
    }
}

textarea {
    display: block;
    font-family: inherit;
    width: 100%;
    word-break: break-word;
    font-weight: normal;
    font-size: 40px;
    margin: 0;
    border: 0;
    padding: 10px 20px;
    background: none;
    border-bottom: 1px solid #ddd;
    color: hsl(231deg 49% 60%);
    font-family: Menlo;
}

.nav-box {
    border-radius: 12px;
    margin-top: 20px;
    background: #fff;
    &:focus-within {
        box-shadow: 0 3px 10px #00000047;
    }
}

.nft-viewer {
    background: url('granite2.jpg');
    padding: 20px;
    min-height: 100vh;

    .nfts-inner {
        max-width: 800px;
        margin: 0 auto;
    }
}

.fungible-actions {
    border-top: 1px solid #f5deb347;
    margin: -20px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    & > * {
        padding: 10px !important;
        flex: 1;
        text-align: center;
        &:first-child {
            background: #f5deb347;
        }
    }
}

.account-holdings {
    display: flex;
    height: 100vh;

    .nfts {
        width: 70vw;
        overflow: auto;
        .nfts-inner {
            box-shadow: 10px 0 10px -10px #6e71919e inset;
            background: url('granite2.jpg');
            padding: 20px 20vw;
            min-height: 100vh;
        }
        button {
            box-shadow: 0 2px 4px #0000001f;
            width: 100%;
            display: block;
            border: 0;
            background: #fff;
            border-radius: 10px;
            font-size: 20px;
            padding: 20px;
        }
    }

    .coins-box {
        background: wheat;
        padding: 20px;
        flex: 1;
        text-align: center;
    }

    .account-details {
        flex: 1;
        overflow: auto;
        display: flex;
        flex-direction: column;
        .logo {
            display: block;
            margin: auto;
            width: 100%;
            path {
                fill: hsl(39deg 55% 50%);
            }
        }
        .slogan {
            color: hsl(39deg 55% 19%);
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 3px;
            padding: 0 20px;
            margin-top: -16px;
            margin-bottom: 30px;
        }
        .account-details-header {
            background: hsl(39deg 77% 94%);
            padding: 20px;
        }
        .fungible-token-held {
            display: inline-block;
            text-align: left;
            .token-balance {
                padding: 20px;
                margin-bottom: 20px;
                margin-right: 20px;
                border-radius: 10px;
                display: inline-block;
                background: #fff;
                overflow: hidden;
                img {
                    width: 100px;
                    height: 100px;
                    border-radius: 100px;
                    display: block;
                    margin: auto;
                    margin-bottom: 10px;
                }
                .balance {
                    font-size: 30px;
                }
                .symbol {
                    color: hsl(231deg 48% 65%);
                }
            }
            @media (max-width: 1000px) {
                display: flex;
                justify-content: center;
            }
        }
    }

    .swap {
        float: right;
        font-size: small;
        padding: 2px;
    }

    @media (max-width: 1000px) {
        display: block;
        height: auto;
        .nfts {
            width: auto;
            .nfts-inner {
                box-shadow: 0px 10px 10px -10px #6e71919e inset;
                min-height: 100vh;
                padding: 20px;
            }
        }

    }
}
